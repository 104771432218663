import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { InspirationService } from 'src/app/main/services/inspiration.service';
import {
  ActivityProps,
  CategoryProps,
  InspirationDraft,
  PrimaryActivity,
  PublishInspiration,
  PublishedInPrimaryCategory,
  PublishedInSecondaryCategory,
  ScheduledPublishInspiration,
  SecondaryActivity,
} from 'src/app/main/types/main.types';
import { DraftType, sleep } from 'src/app/main/utilities';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inspiration-preview',
  templateUrl: './inspiration-preview.component.html',
})
export class InspirationPreviewComponent implements OnInit, OnDestroy {
  subscriptionRef: Subscription;

  isLoading = !0;

  submitted = false;

  previewFormGroup!: FormGroup;

  scheduleVisible = false;

  draftType = DraftType.PUBLISHED;

  draftId: string | null;

  minDate = new Date();

  content: string;

  scheduleDate: Date = new Date();

  draftedInspiration: InspirationDraft;

  separatorExp = /,| /;

  isPastDate = false;

  timePicker: { time: Date; hour: number; min: number };

  visibilities: { name: string; key: string }[];

  primaryCategory: PublishedInPrimaryCategory[];

  secondaryCategory: PublishedInSecondaryCategory[];

  primaryActivity: PrimaryActivity[];

  secondaryActivity: SecondaryActivity[];

  categoriesA: CategoryProps[];

  categoriesB: CategoryProps[];

  activitiesA: ActivityProps[];

  activitiesB: ActivityProps[];

  categoriesAClone: CategoryProps[];

  categoriesClone: CategoryProps[];

  activitiesClone: ActivityProps[];

  activitiesBClone: ActivityProps[];

  startWithNumberRegex = /^\d/;

  alphaNumericRegex = /^[a-zA-Z0-9]{3,20}$/;

  sidebarEvent: { drawerOpen: boolean; isMobileScreen: boolean };

  dateTimePickerEvent: Subject<any> = new Subject<any>();

  constructor(
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private builder: FormBuilder,
    private domSanitizer: DomSanitizer,
    private inspirationService: InspirationService,
  ) {}

  ngOnInit(): void {
    this.draftId = this.route.snapshot.paramMap.get('draftId');
    this.createAdvanceSettingForm();
    this.setDropdownValues();
    this.getDraftedInspiration();
    this.getCategories();
    this.getActivities();
  }

  ngOnDestroy(): void {
    this.subscriptionRef.unsubscribe();
  }

  drawerOpenChanged(e: { drawerOpen: boolean; isMobileScreen: boolean }) {
    this.sidebarEvent = e;
  }

  createAdvanceSettingForm() {
    this.previewFormGroup = this.builder.group({
      categoryPrimary: [null, Validators.required],
      categorySecondary: [null],
      activityPrimary: [null, Validators.required],
      activitySecondary: [null],
      commentAllowed: [true],
      commentModeration: [{ value: false, disabled: true }],
      visibility: ['PUBLIC'],
      scheduleDate: [null],
      copyright: [true],
      language: [null],
      license: [null],
      location: [null],
      snippet: [null],
      tags: [null],
    });

    this.subscriptionRef = this.previewFormGroup.valueChanges.subscribe(
      (next) => {
        const {
          categoryPrimary,
          categorySecondary,
          activityPrimary,
          activitySecondary,
        } = next;

        if (categoryPrimary) {
          this.categoriesB = this.categoriesClone.filter(
            (value) => value.key !== categoryPrimary.key,
          );
        }

        if (categorySecondary) {
          this.categoriesA = this.categoriesClone.filter(
            (value) => value.key !== categorySecondary.key,
          );
        }

        if (activityPrimary) {
          this.activitiesB = this.activitiesClone.filter(
            (value) => value.key !== activityPrimary.key,
          );
        }

        if (activitySecondary) {
          this.activitiesA = this.activitiesClone.filter(
            (value) => value.key !== activitySecondary.key,
          );
        }

        !categorySecondary && (this.categoriesA = this.categoriesClone);
        !activitySecondary && (this.activitiesA = this.activitiesClone);
      },
    );
  }

  setDropdownValues() {
    this.primaryCategory = [{ name: 'Trial Info', code: 'TRAIL_INFO' }];

    this.secondaryCategory = [{ name: 'Trial Info ', code: 'TRAIL_INFO' }];

    this.primaryActivity = [{ name: 'General', code: 'GENERIC' }];

    this.secondaryActivity = [{ name: 'General', code: 'GENERIC' }];

    this.visibilities = [
      { name: 'Public', key: 'PUBLIC' },
      { name: 'Unlisted', key: 'UNLISTED' },
      { name: 'Private', key: 'PRIVATE' },
    ];
  }

  getDraftedInspiration() {
    this.inspirationService.getDraftById(this.draftId as string).subscribe({
      next: (data: any) => {
        this.draftedInspiration = data;
        this.content = this.domSanitizer.bypassSecurityTrustHtml(
          data.content,
        ) as string;
        this.previewFormGroup.get('snippet')?.patchValue(data.snippet);

        if (data.settingData) {
          const {
            activityPrimary,
            activitySecondary,
            categoryPrimary,
            categorySecondary,
            commentAllowed,
            commentModeration,
            visibility,
          } = data.settingData;
          this.previewFormGroup
            .get('activityPrimary')
            ?.patchValue(
              this.primaryActivity.find(
                (primary) => primary.code === activityPrimary,
              ),
            );
          this.previewFormGroup
            .get('activitySecondary')
            ?.patchValue(
              this.secondaryActivity.find(
                (secondary) => secondary.code === activitySecondary,
              ),
            );
          this.previewFormGroup
            .get('categoryPrimary')
            ?.patchValue(
              this.primaryCategory.find(
                (primary) => primary.code === categoryPrimary,
              ),
            );
          this.previewFormGroup
            .get('categorySecondary')
            ?.patchValue(
              this.secondaryCategory.find(
                (secondary) => secondary.code === categorySecondary,
              ),
            );
          this.previewFormGroup
            .get('commentAllowed')
            ?.patchValue(commentAllowed);
          this.previewFormGroup
            .get('commentModeration')
            ?.patchValue(commentModeration);
          this.previewFormGroup.get('visibility')?.patchValue(visibility);
        }
      },
      error: (err) => {
        const { errors } = err.error;
        this.messageService.add({
          severity: 'error',
          summary: 'Inspiration',
          detail: errors[0],
        });
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  scheduleInspiration(data: ScheduledPublishInspiration) {
    this.inspirationService
      .schedule(data, this.draftedInspiration.id)
      .subscribe({
        next: (data: any) => {
          this.isLoading = false;
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Schedule Inspiration',
            detail: 'Inspiration is scheduled',
          });
          this.scheduleVisible = false;
          if (data.draftType === 'SCHEDULE')
            this.router.navigate(['/inspiration', 'authored'], {
              queryParams: {
                tab: 'scheduled',
                id: data.id,
              },
            });
        },
        error: (err) => {
          const { errors = null } = err?.error || {};
          this.messageService.add({
            severity: 'error',
            summary: 'Schedule Inspiration',
            detail: errors ? errors[0] : 'Something went wrong',
          });
          this.isLoading = false;
        },
      });
  }

  publishInspiration(data: PublishInspiration) {
    this.inspirationService
      .publish(data, this.draftedInspiration.id)
      .subscribe({
        next: (data: any) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Publish Inspiration',
            detail: 'Inspiration Published Successfully',
          });
          this.router.navigate(['/inspiration', 'authored'], {
            queryParams: {
              tab: 'published',
              id: data.id,
            },
          });
        },
        error: (err) => {
          const { errors = null } = err?.error || {};
          this.messageService.add({
            severity: 'error',
            summary: 'Publish Inspiration',
            detail: errors ? errors[0] : 'Something went wrong',
          });
          this.isLoading = false;
        },
      });
  }

  onSubmit() {
    this.submitted = true;
    const { value, valid } = this.previewFormGroup;

    if (!valid) {
      this.scrollToErrorElement();
    }

    if (!this.validateTags() && !valid) {
      return;
    }

    if (!valid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Required Fields',
        detail: 'Please fill the required fields',
      });
    }

    if (valid) {
      const ddValues = {
        draftId: this.draftedInspiration.id,
        categoryPrimary: value.categoryPrimary.key,
        categorySecondary: value.categorySecondary?.key,
        activityPrimary: value.activityPrimary.key,
        activitySecondary: value.activitySecondary?.key,
      };

      const tags = value.tags
        ? value.tags.map((t: string) => ({ key: t }))
        : null;

      if (DraftType.SCHEDULE === this.draftType) {
        if (!this.timePicker) {
          return;
        }

        value.scheduleDate = this.mergeDateTime(value.scheduleDate);

        if (new Date(value.scheduleDate) < new Date() && this.timePicker) {
          this.isPastDate = !0;
          return;
        }

        this.isLoading = true;
        this.isPastDate = !1;
        const { scheduleDate, ...pRequest } = value;
        this.scheduleInspiration({
          scheduleDate,
          publishRequest: {
            ...pRequest,
            ...ddValues,
            tags,
          },
        });
      } else {
        this.isLoading = true;
        const { scheduleDate, ...rest } = value;
        this.publishInspiration({
          ...rest,
          ...ddValues,
          tags,
        });
      }
    }
  }

  scheduleDialog(hide = false) {
    !hide && (this.draftType = DraftType.SCHEDULE);
    hide && (this.draftType = DraftType.PUBLISHED);
  }

  backToEdit() {
    this.router.navigate(['/inspiration', 'edit'], {
      queryParams: { draftId: this.draftedInspiration.id },
    });
  }

  onDateSelect(e: any) {
    console.log(e);
    this.previewFormGroup.get('scheduleDate')?.patchValue(e);
  }

  onTimeSelect(e: any) {
    this.timePicker = e.value;
  }

  mergeDateTime(time: string) {
    if (this.timePicker) {
      const startTime = new Date(time);
      startTime.setHours(this.timePicker?.hour);
      startTime.setMinutes(this.timePicker?.min);
      console.log(startTime);
      return startTime;
    }
    return null;
  }

  getInspirationCoverImage() {
    return (
      environment.apiUrl +
      '/api/public/inspirations/draft/' +
      this.draftId +
      '/cover'
    );
  }

  schedule() {
    const _primaryActivites =
      this.previewFormGroup.get('categoryPrimary')?.value;
    const _primaryCategory =
      this.previewFormGroup.get('activityPrimary')?.value;
    if (!_primaryCategory && !_primaryActivites) {
      this.previewFormGroup.get('categoryPrimary')?.setErrors({ required: !0 });
      this.previewFormGroup.get('activityPrimary')?.setErrors({ required: !0 });
      this.messageService.add({
        severity: 'error',
        summary: 'Required Fields',
        detail: 'Please fill the required fields',
      });
    } else {
      this.previewFormGroup.get('categoryPrimary')?.setErrors(null);
      this.previewFormGroup.get('activityPrimary')?.setErrors(null);
      this.scheduleVisible = true;
    }
  }

  getCategories() {
    this.inspirationService.getInspirationCategories().subscribe({
      next: (data: any) => {
        this.categoriesA = data;
        this.categoriesB = data;
        this.categoriesClone = data;
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Categories',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  getActivities() {
    this.inspirationService.getInspirationActivities().subscribe({
      next: (data: any) => {
        this.activitiesA = data;
        this.activitiesB = data;
        this.activitiesClone = data;
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Activities',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  validateTags() {
    const tags = this.previewFormGroup.get('tags')?.value;
    let hasError = !1;

    if (tags && tags.length) {
      for (const tag of tags) {
        if (
          this.startWithNumberRegex.test(tag) ||
          !this.alphaNumericRegex.test(tag)
        ) {
          hasError = !0;
        }
      }

      if (hasError) {
        this.previewFormGroup.get('tags')?.setErrors({ notAlphanumeric: !0 });
      } else {
        this.previewFormGroup.get('tags')?.setErrors(null);
      }
    }
    return hasError;
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/public/user/' + id + '/pic';
  }

  async scrollToErrorElement() {
    const invalidElement = document.querySelector(
      `app-inspiration-preview .surface-ground`,
    ) as HTMLInputElement;
    if (invalidElement) {
      invalidElement?.scrollTo({ top: 750, behavior: 'smooth' });
      await sleep(300);
      invalidElement.focus();
    }
  }

  get f() {
    return this.previewFormGroup.controls;
  }
}

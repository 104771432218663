import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InspirationAuthoredComponent } from './inspiration-authored/inspiration-authored.component';
import { InspirationBookmarkComponent } from './inspiration-bookmark/inspiration-bookmark.component';
import { InspirationCreateComponent } from './inspiration-create/inspiration-create.component';
import { InspirationFollowingComponent } from './inspiration-following/inspiration-following.component';
import { InspirationOverviewComponent } from './inspiration-overview/inspiration-overview.component';
import { InspirationPreviewComponent } from './inspiration-preview/inspiration-preview.component';
import { InspirationRoutingModule } from './inspiration-routing.module';
import { InspirationSharedComponent } from './inspiration-shared/inspiration-shared.component';
import { InspirationViewComponent } from './inspiration-view/inspiration-view.component';
import { InspirationComponent } from './inspiration.component';
import { NewsletterAndSubscribersComponent } from './newsletter-and-subscribers/newsletter-and-subscribers.component';
import { SendNewsletterCreateComponent } from './send-newsletter/send-newsletter-create/send-newsletter-create.component';
import { SendNewsletterListComponent } from './send-newsletter/send-newsletter-list/send-newsletter-list.component';
import { SendNewsletterPreviewComponent } from './send-newsletter/send-newsletter-preview/send-newsletter-preview.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SpeedDialModule } from 'primeng/speeddial';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { SharedModule } from 'src/app/shared/shared.module';
import { PublisherOverviewComponent } from './publisher-overview/publisher-overview.component';
import { PublisherProfileComponent } from './publisher-profile/publisher-profile.component';

@NgModule({
  declarations: [
    InspirationComponent,
    InspirationOverviewComponent,
    InspirationFollowingComponent,
    InspirationBookmarkComponent,
    InspirationSharedComponent,
    InspirationAuthoredComponent,
    InspirationViewComponent,
    InspirationCreateComponent,
    InspirationPreviewComponent,
    PublisherOverviewComponent,
    PublisherProfileComponent,
    SendNewsletterCreateComponent,
    SendNewsletterListComponent,
    NewsletterAndSubscribersComponent,
    SendNewsletterPreviewComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    InspirationRoutingModule,
    OverlayPanelModule,
    AvatarModule,
    RadioButtonModule,
    DividerModule,
    ButtonModule,
    DialogModule,
    TieredMenuModule,
    ImageCropperModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    EditorModule,
    CardModule,
    SpeedDialModule,
    ChipModule,
    TabViewModule,
    DropdownModule,
    CheckboxModule,
    ChipsModule,
    InfiniteScrollModule,
    SelectButtonModule,
    BadgeModule,
    ScrollPanelModule,
    CarouselModule,
    PaginatorModule,
    InputSwitchModule,
  ],
  exports: [InspirationOverviewComponent],
})
export class InspirationModule {}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';
import { Subject } from 'rxjs';
import { InspirationService } from 'src/app/main/services/inspiration.service';
import { PublishInspiration } from 'src/app/main/types/main.types';
import {
  getKey,
  getValue,
  groupByDate,
  visibilityMap,
} from 'src/app/main/utilities';
import { inspirationOptions } from 'src/app/main/utilities/overlayHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inspiration-bookmark',
  templateUrl: './inspiration-bookmark.component.html',
})
export class InspirationBookmarkComponent implements OnInit {
  currentPage = 1;

  isLoading = false;

  inspirationId = 0;

  showSnippet: any = {};

  _groupByDate: any = [];

  bookmarkOptions = inspirationOptions;

  _getKey = getKey;

  _getValue = getValue;

  visibilityMap = visibilityMap;

  bookmarkedInspirations: PublishInspiration[] = [];

  shareLinkEvent: Subject<boolean> = new Subject<boolean>();

  @ViewChild('bookmarkMenu', { static: false })
  bookmarkMenuRef: TieredMenu;

  constructor(
    private inspirationService: InspirationService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getBookmarkedInspirations(this.currentPage - 1);
  }

  onScrolledDown() {
    this.currentPage++;
    this.getBookmarkedInspirations(this.currentPage - 1);
  }

  getBookmarkedInspirations(page: number) {
    this.inspirationService.getBookmarkedInspirationsFeatures(page).subscribe({
      next: (data: any) => {
        this.bookmarkedInspirations = [
          ...this.bookmarkedInspirations,
          ...data.content,
        ];
        this.showSnippet = this.bookmarkedInspirations.reduce(
          (prev: any, initial: any) => {
            prev[initial.id] = false;
            return prev;
          },
          {},
        );
        this._groupByDate = groupByDate(
          this.bookmarkedInspirations,
          'bookmarkDate',
          ['bookmarkDate'],
        );
        this.isLoading = false;
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Bookmarked Inspirations',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isLoading = false;
      },
    });
  }

  onTieredMenuClick(id: string) {
    switch (id) {
      case 'view':
        this.router.navigate(['/inspiration', 'view', this.inspirationId]);
        break;
      case 'share':
        this.shareLinkEvent.next(true);
        break;
      case 'remove':
        this.inspirationService.unbookmark('' + this.inspirationId).subscribe({
          next: (data: any) => {
            this.messageService.add({
              severity: data.error ? 'error' : 'success',
              summary: 'Bookmark',
              detail: data.message,
            });
            this.currentPage = 1;
            this.bookmarkedInspirations = [];
            this.getBookmarkedInspirations(this.currentPage - 1);
          },
          error: (err) => {
            const { errors = null } = err?.error || {};
            this.messageService.add({
              severity: 'error',
              summary: 'Bookmark',
              detail: errors ? errors[0] : 'Something went wrong',
            });
            console.log('Error in onTieredMenuClick: ', err);
          },
        });
        break;
    }
  }

  onBookmarkedView(id: string) {
    this.router.navigate(['/inspiration/view', id]);
  }

  getInspirationCoverImage(id: string | null) {
    return environment.apiUrl + '/api/public/inspirations/' + id + '/cover';
  }

  showTruncateText(id: number) {
    this.showSnippet[id] = !this.showSnippet[id];
  }

  toggleMenu(e: any, data: any) {
    this.bookmarkMenuRef.toggle(e);
    this.inspirationId = data.id;
  }
}

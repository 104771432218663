import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ActivityProps,
  CategoryProps,
  PublishInspiration,
} from 'src/app/main/types/main.types';
import { publishedInMap, relatedActivitiesMap } from 'src/app/main/utilities';

@Component({
  selector: 'app-inspiration-view',
  templateUrl: './inspiration-view.component.html',
})
export class InspirationViewComponent implements OnInit, OnDestroy {
  isLoading = !0;

  subscription: Subscription;

  inspirationId: string | null;

  inspiration: PublishInspiration;

  publishedInMap = publishedInMap;

  relatedActivitiesMap = relatedActivitiesMap;

  tableOfContent: any;

  categories: CategoryProps[];

  activities: ActivityProps[];

  sidebarEvent: { drawerOpen: boolean; isMobileScreen: boolean };

  constructor(private route: ActivatedRoute) {
    this.subscription = this.route.url.subscribe((u) => {
      const view = window.location.href.split('#')[1];
      if (view) {
        const drawerContent = document.querySelector('.mat-drawer-content');
        const shadowedDOM =
          document.getElementById('shadowed-content')?.firstElementChild;
        const scrollViewEl = shadowedDOM?.shadowRoot?.querySelector(
          `[name="${view}"]`,
        );
        const bounding = scrollViewEl?.getBoundingClientRect();
        if (bounding) {
          drawerContent?.scrollTo({
            top: bounding.top,
            behavior: 'smooth',
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.inspirationId = this.route.snapshot.paramMap.get('id');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  drawerOpenChanged(e: { drawerOpen: boolean; isMobileScreen: boolean }) {
    this.sidebarEvent = e;
  }

  getView(data: any) {
    this.inspiration = data.inspiration;
    this.tableOfContent = data.content;
    this.isLoading = !1;
  }

  getCategoriesAndActivities(data: any, isCategory = !0) {
    isCategory && (this.categories = data);
    !isCategory && (this.activities = data);
  }

  getCategory(category: string) {
    return this.categories?.find((c) => c.key === category)?.label || '';
  }

  getActivity(activity: string) {
    return this.activities?.find((c) => c.key === activity)?.label || '';
  }
}

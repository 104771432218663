import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CropperPosition, ImageTransform } from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';
import { Editor } from 'primeng/editor';
import { TieredMenu } from 'primeng/tieredmenu';
import { Observable, Subject, tap } from 'rxjs';
import { InspirationService } from 'src/app/main/services/inspiration.service';
import { PlatformService } from 'src/app/main/services/platform.service';
import { AppState } from 'src/app/main/store/model/state.model';
import {
  App,
  DialogEvent,
  InspirationDraft,
  Publication,
  Publisher,
  UserDetails,
} from 'src/app/main/types/main.types';
import { quillMapHandler } from 'src/app/main/utilities';
import { validateImage } from 'src/app/main/utilities/imageHelper';
import { cropperMenuOptions } from 'src/app/main/utilities/overlayHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-send-newsletter-create',
  templateUrl: './send-newsletter-create.component.html',
})
export class SendNewsletterCreateComponent implements OnInit, OnDestroy {
  isDark = false;
  isLoading = false;
  isPageLoading = false;
  responsiveOptions: any[] | undefined;
  croppedImage: any = null;
  cropperMenuOptions = cropperMenuOptions;
  file: File;
  sourceImage: any = '';
  imageChangedEvent: any;
  visible = false;
  scale = 1;
  profilePic = '';
  ratio: number = 16 / 9;
  transform: ImageTransform = {};
  cropperPosition: CropperPosition;
  blob: Blob | null | undefined;
  quill: any;
  title: string;
  draft: InspirationDraft;
  draftId: string | null;
  user: UserDetails;
  userDetail$: Observable<UserDetails>;
  titlePlaceholder = '';
  contentPlaceholder = '';
  caption = '';
  coverPhotoCaption = '';
  isBrowser = true;
  appState$: Observable<App>;
  publishers: Publisher[] = [];
  publications: Publication[] = [];
  publishas: Publisher | null;
  publicationto: Publication;
  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();
  @ViewChild('upload') uploadRef: ElementRef;
  @ViewChild('textarea') textAreaRef: ElementRef;
  @ViewChild('manage', { static: false }) manageRef: TieredMenu;
  @ViewChild('inspirationEditor', { static: false }) editorRef: Editor;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private inspirationService: InspirationService,
    private store: Store<AppState>,
    private platformService: PlatformService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.draftId = this.route.snapshot.queryParamMap.get('draftId');
    if (this.draftId) {
      this.getDraftedInspiration(this.draftId);
    }

    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.user = user;
          this.profilePic =
            environment.apiUrl + '/api/user/' + this.user.id + '/pic';
        }),
      );
    this.userDetail$.subscribe();
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
          this.changeKeenSlideTheme(appState.isDark);
        }),
      );
    this.appState$.subscribe();
    this.changePageStyle();
  }

  async loadQuillModule(_editor: any) {
    if (this.platformService.getIsBrowser()) {
      const { Delta } = await import('quill/core');
      this.quill = _editor.editor;
      this.quill.clipboard.addMatcher(
        Node.ELEMENT_NODE,
        function (node: HTMLElement) {
          return new Delta().insert(node.innerText);
        },
      );
      const Block = this.editorRef.dynamicQuill.import('blots/block');
      const Inline = this.editorRef.dynamicQuill.import('blots/inline');
      Block.tagName = 'DIV';
      Inline.tagName = 'SPAN';
      this.editorRef.dynamicQuill.register(Block, true);
      this.editorRef.dynamicQuill.register(Inline, true);
      quillMapHandler(this.editorRef.dynamicQuill);
    }
  }

  onEditorInit(e: any) {
    this.loadQuillModule(e);
  }

  ngOnDestroy(): void {
    this.changePageStyle(!0);
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length) {
      this.file = event.target.files[0];
      validateImage(this.file)
        .then(() => {
          this.imageChangedEvent = event;
          this.visible = true;
        })
        .catch((err) => {
          this.messageService.add(err);
        });
    }
  }

  updateInspiration() {
    this.isPageLoading = true;
    this.inspirationService
      .updateDraft(this.draftId, {
        title: this.title,
        content: this.editorRef.getQuill().root.innerHTML,
        publisherId: this.publishas?.id || null,
        publicationId: this.publicationto.id || null,
        coverImageCaption: this.coverPhotoCaption,
      })
      .subscribe({
        next: (data: any) => {
          this.router.navigate(['/inspiration', 'preview', data.id], {
            state: data,
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Inspiration',
            detail: err.error.message,
          });
          this.isPageLoading = false;
        },
        complete: () => {
          this.isPageLoading = false;
        },
      });
  }

  onSubmit() {
    if (this.draftId) {
      this.updateInspiration();
    }
  }

  getDraftedInspiration(id: string) {
    this.isPageLoading = true;
    this.getPublisher(0);
    this.isPageLoading = false;
  }

  discardDraft() {
    this.isPageLoading = true;
    this.inspirationService.discardDraft(this.draftId).subscribe({
      next: (data: any) => {
        this.isPageLoading = false;
        if (data.error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Discard Draft',
            detail: data.message,
          });
        } else {
          this.router.navigate(['/inspiration', 'authored']);
        }
      },
      error: (err) => {
        const { errors = null } = err?.error || {};
        this.messageService.add({
          severity: 'error',
          summary: 'Discard Draft',
          detail: errors ? errors[0] : 'Something went wrong',
        });
        this.isPageLoading = false;
      },
    });
  }

  toggleMenu(e: any) {
    this.manageRef.toggle(e);
  }

  onTieredMenuItemClick(action: string) {
    switch (action) {
      case 'manage':
        this.discardDraft();
    }
  }

  changePageStyle(isDestroy = false) {
    const mpMainEl = this.document.querySelectorAll('.mp-main');
    if (mpMainEl.length) {
      mpMainEl.forEach((node: Element, index: number) => {
        if (!isDestroy) {
          index === 0 && node.setAttribute('style', 'height:unset;');
          index === 1 &&
            (node.setAttribute('style', 'overflow:auto;height:unset;'),
            this.document.body.setAttribute('style', 'overflow:hidden;'));
        } else {
          node.removeAttribute('style');
          this.document.body.removeAttribute('style');
        }
      });
    }
  }

  changeKeenSlideTheme(isDark: boolean) {
    const keenSliders = this.document.querySelectorAll('.keen-slider__slide');
    const keenThumbnail = this.document.querySelectorAll(
      '.keen-slider-thumbnail',
    );
    if (keenSliders.length && keenThumbnail.length) {
      keenSliders.forEach((slider: any) => {
        slider.style.background = isDark
          ? 'rgba(1, 73, 94, 0.4)'
          : 'rgb(217, 251, 246)';
      });
      keenThumbnail.forEach((thumbnail: any) => {
        thumbnail.style.background = isDark
          ? 'rgb(13 31 56)'
          : 'rgb(164 227 218)';
      });
    }
  }

  getInspirationDraftCoverImage(id: string | null) {
    return (
      environment.apiUrl + '/api/public/inspirations/draft/' + id + '/cover'
    );
  }

  seeMore(activeIndex: number) {
    this.dialogEvent.next({
      visible: true,
      dialogType: 'INSPIRATION',
      activeIndex,
      data: {
        publications: this.publications,
        publisher: this.publishers,
        selectedPublisher: this.publishas,
        selectedPublishAs: this.publicationto,
      },
    });
  }

  getPublisher(id: number) {
    this.inspirationService.getPublishers().subscribe({
      next: (data: any) => {
        this.publishers = data;
        this.publishas =
          this.publishers.find((p: Publisher) => p.id === id) || null;
        //  this.getPublication(this.publishas?.id);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Publisher',
          detail: err.error.message,
        });
        this.isPageLoading = false;
      },
    });
  }

  getPublication(id: number | undefined) {
    this.inspirationService.getPublications('' + id).subscribe({
      next: (data: any) => {
        this.publications = data;
        this.publicationto = this.publications[0];
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Publication',
          detail: err.error.message,
        });
        this.isPageLoading = false;
      },
    });
  }

  publishData(e: any) {
    const latestPublishAsIndex = this.publishers.findIndex(
      (p) => p.id === e.publishAs.id,
    );
    const latestPublisToIndex = e.publications.findIndex(
      (p: any) => p.id === e.publishTo.id,
    );

    if (latestPublishAsIndex > -1) {
      this.publishers.unshift(this.publishers[latestPublishAsIndex]);
      this.publishers.splice(latestPublishAsIndex + 1, 1);
    }

    if (latestPublisToIndex > -1) {
      e.publications.unshift(e.publications[latestPublisToIndex]);
      e.publications.splice(latestPublisToIndex + 1, 1);
    }

    this.publishas = e.publishAs;
    this.publicationto = e.publishTo;
    this.publications = e.publications;
  }

  onPublisherChange(e: any) {
    this.getPublication(e.value.id);
  }

  onTextAreaChange(e: any) {
    this.textAreaRef.nativeElement.style.height = 'auto';
    this.textAreaRef.nativeElement.style.height =
      this.textAreaRef.nativeElement.scrollHeight + 'px';
  }
}
